<template>
  <div class="electricitytopup">
    <div class="title">
      {{ $t("electricity.title") }}
    </div>
    <div class="tx">
      <div class="num-title">
        {{ $t("electricity.rechargeNum") }}
      </div>
      <div class="num">
        <van-field
          class="left"
          v-model="mentionCoinNumber"
          type="number"
          :center="true"
          :placeholder="$t('electricity.place')"
          @update:model-value="textChange(mentionCoinNumber)"
          :border="false"
        />
        <div class="right" @click="allNumber">
          All
        </div>
      </div>
      <div class="ktye">
        {{ $t("electricity.ktyeLeft") }}
        {{ balance }} USDT
        {{ $t("electricity.ktyeRight") }}
      </div>
    </div>
    <van-button
      class="cz_button"
      type="primary"
      :disabled="!canWithdrawal"
      @click="close(true)"
    >
      {{ $t("electricity.button2") }}
    </van-button>
    <van-dialog
      class="dia"
      v-model:show="show"
      @confirm="addPowerFee"
      show-cancel-button
    >
      {{ $t("electricity.dialog") }}
    </van-dialog>

    <!-- <van-overlay :show="show" @click="close(false)">
      <div class="wrapper" @click.stop>
        <div class="block">
          <div class="close_box">
            <div class="close_button" @click="close(false)">
              ❌
            </div>
            <div class="content">
              请输入支付密码
            </div>
          </div>
          <div class="font">
            充值电费
          </div>
          <div class="b_money">{{ mentionCoinNumber }} USDT</div>
          <van-password-input
            :value="value"
            :error-info="errorInfo"
            :focused="showKeyboard"
            @focus="showKeyboard = true"
          />
        </div>

        <van-number-keyboard
          v-model="value"
          :show="showKeyboard"
          @blur="showKeyboard = false"
        />
      </div>
    </van-overlay> -->
  </div>
</template>

<script>
import { Notify } from "vant"

export default {
  name: "my",
  data() {
    return {
      value: "123",
      errorInfo: "",
      showKeyboard: true,
      show: false,
      mentionCoinNumber: "", //充值数量
      balance: 0, //可充值额度
      canWithdrawal: false,
    }
  },
  watch: {
    value(newVal) {
      this.setTabActive()
    },
    value(newVal) {
      console.log(newVal, newVal.length === 6 && newVal === "123333")
      if (newVal.length === 6 && newVal === "123333") {
        this.close(false)
        Notify({ type: "success", message: "充值成功" })
        this.$router.go(-1)
      } else if (newVal.length === 6 && newVal !== "123456") {
        this.errorInfo = "密码错误"
      } else {
        this.errorInfo = ""
      }
    },
  },
  mounted() {
    this.getMeInforMation()
  },
  methods: {
    go(url) {
      this.$router.push(url)
    },
    // 获取用户信息
    getMeInforMation() {
      this.$request
        .get("/user/userInfo")
        .then(
          (res) => {
            if (res.code == 200) {
              this.balance = res.data.usdtBalance
            } else {
              this.$toast(res.msg)
              if (res.code == 1018) {
                this.$router.push("/login")
              }
            }
            // 执行成功的回调函数
          },
          (error) => {
            console.log(error)
            // 执行失败的回调函数
          }
        )
        .catch((err) => {
          this.$toast(err)
        })
    },
    close(type) {
      if (type == true) {
        this.show = true
        this.showKeyboard = true
      } else {
        this.show = false
        this.showKeyboard = false
      }
    },
    textChange(e) {
      console.log("e", e)
      if (
        this.mentionCoinNumber != "" &&
        Number(this.mentionCoinNumber) >= 0.01
      ) {
        this.mentionCoinNumber = Number(
          e.toString().match(/^\d+(?:\.\d{0,2})?/)
        )
      } else {
        this.$toast("The amount of recharge must not be less than 0.01USDT")
      }
      if (
        Number(this.mentionCoinNumber) >= 0.01 &&
        this.balance >= 0.01 &&
        Number(this.mentionCoinNumber) <= Number(this.balance)
      ) {
        this.canWithdrawal = true
      } else {
        this.canWithdrawal = false
      }
    },
    //点击全部
    allNumber() {
      this.mentionCoinNumber = this.balance
      // this.accountNumber = this.mentionCoinNumber
      this.textChange(this.mentionCoinNumber)
    },
    addPowerFee() {
      const params = {
        amount: this.mentionCoinNumber,
      }
      this.$request
        .post("/user/addPowerFee", params)
        .then(
          (res) => {
            if (res.code == 200) {
              this.$toast.success({
                message: "success",
                duration: 1000,
              })
              setTimeout(() => {
                this.$router.go(-1)
              }, 1000)
            } else {
              this.$toast(res.msg)
              if (res.code == 1018) {
                this.$router.push("/login")
              }
            }
            // 执行成功的回调函数
          },
          (error) => {
            console.log(error)
            // 执行失败的回调函数
          }
        )
        .catch(() => {})
    },
  },
}
</script>
<style scoped lang="scss">
.electricitytopup {
  font-weight: bolder;
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 0 20px 90px;
  background: #f9f9f9;

  .title {
    font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
    font-size: 18px;
    color: #4a4a4a;
    text-align: left;
    font-style: 700;
    margin: 30px 0 20px;
  }
  .tx {
    width: 100%;
    height: 156px;
    padding: 16px 20px;
    background: #ffffff;
    border-radius: 8px;
    .num-title {
      font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
      font-size: 15px;
      color: #666666;
      text-align: left;
      font-style: 700;
    }
    .num {
      width: 100%;
      height: 52px;
      background: #f9f9f9;
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      padding: 10px 0;
      .left {
        width: 220px;
        // height: 37px;
        // font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
        font-weight: 700;
        font-size: 24px;
        color: #333333;
        text-align: left;
        background: #f9f9f9;
        padding: 0;
        line-height: 37px;
      }
      .right {
        font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
        font-weight: 700;
        font-size: 15px;
        color: #f8b34d;
        text-align: left;
        padding: 0 12px;
      }
    }
    .ktye {
      width: 100%;
      margin-top: 10px;
      font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
      font-weight: 700;
      font-size: 13px;
      color: #666666;
      text-align: right;
      padding-right: 4px;
    }
  }
  .cz_button {
    width: 100%;
    height: 50px;
    border: 0;
    background: #f8de4d;
    color: #4a4a4a;
    border-radius: 25px;
    margin-top: 40px;
  }
  .wrapper {
    width: 100%;
    display: flex;
    // align-items: center;
    justify-content: center;
    height: 100%;
  }
  .block {
    width: 315px;
    height: 250px;
    background: #ffffff;
    border-radius: 8px;
    padding: 22px 0 30px;
    margin-top: 150px;
    display: flex;
    flex-direction: column;
    .close_box {
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      position: relative;
      .close_button {
        position: absolute;
        left: 20px;
      }
    }
    .font {
      font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
      font-size: 14px;
      color: #999999;
      margin-bottom: 8px;
    }
    .b_money {
      font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
      font-size: 28px;
      color: #333333;
      margin-bottom: 20px;
    }
  }
}
</style>

<style lang="scss">
.van-dialog__content {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.van-field__control {
  text-indent: 10px;
}
</style>
